import React from "react";
import { DefaultCharacter } from "../localContext";

export const defaultContext = {
    characterData: {...DefaultCharacter},
    currentCharacterId: null,
    creationStep: 0
};

export const CreationContext = React.createContext({
    context: defaultContext,
    setContext: () => {}
});
