import React, { useCallback, useState } from "react";
import { ITEM, getItemsByType } from "./itemShopService";
import { rulebook } from "../../styling";
import ShopItems, { ShopType } from "../shopItems";

function ItemShop() {
    const [loadingItemShopItems, setLoadingItemShopItems] = useState(false);
    const [itemShopArray, setItemShopArray] = useState([]);
    const [showStartingItems, setShowStartingItems] = useState(true);
    const [showArmor, setShowArmor] = useState(false);
    const [showConsumables, setShowConsumables] = useState(false);
    const [showServices, setShowServices] = useState(false);
    const [showWeapons, setShowWeapons] = useState(false);
    const [textFilter, setTextFilter] = useState("");

    const getItemShopData = useCallback(async () => {
        setLoadingItemShopItems(true);
        let itemArray = [];
        setItemShopArray(itemArray
            .concat(showStartingItems ? await getItemsByType(textFilter, ITEM.STARTING) : [])
            .concat(showArmor ? await getItemsByType(textFilter, ITEM.ARMOR) : [])
            .concat(showConsumables ? await getItemsByType(textFilter, ITEM.CONSUMABLES) : [])
            .concat(showServices ? await getItemsByType(textFilter, ITEM.SERVICES) : [])
            .concat(showWeapons ? await getItemsByType(textFilter, ITEM.WEAPONS) : [])
            .sort(function(a,b){ return a.cost - b.cost;})
        );
        setTimeout(() => setLoadingItemShopItems(false), 1000);
    }, [showStartingItems, showArmor, showConsumables, showServices, showWeapons, textFilter]);

    const handleKeyPress = (event) => {
        setTextFilter(document.getElementById('search-bar').value);
        if (event.key === 'Enter') getItemShopData();
    }

    const toggleStartingItems = () => {
        const showingStartingItems = !showStartingItems;
        
        if (showingStartingItems) {
            setShowArmor(false);
            setShowConsumables(false);
            setShowServices(false);
            setShowWeapons(false);
        }

        setShowStartingItems(showingStartingItems);
    }

    const toggleSearchAll = () => {
        const searchAll = !(
            showArmor && showConsumables && showServices && showWeapons
        );
        setShowArmor(searchAll);
        setShowConsumables(searchAll);
        setShowServices(searchAll);
        setShowWeapons(searchAll);
        setShowStartingItems(false);
    }

    const toggleItemType = (itemType) => {
        setShowStartingItems(false);

        if (itemType === ITEM.ARMOR)
            setShowArmor(!showArmor);
        else if (itemType === ITEM.CONSUMABLES)
            setShowConsumables(!showConsumables);
        else if (itemType === ITEM.SERVICES)
            setShowServices(!showServices);
        else if (itemType === ITEM.WEAPONS)
            setShowWeapons(!showWeapons);
    }

    const searchUI = (
        <center>
            <div>
                <label>
                    <input type="checkbox"
                        checked={showArmor && showConsumables && showServices && showWeapons}
                        onChange={toggleSearchAll}
                        />
                        All Items &nbsp;&nbsp;|&nbsp;&nbsp;
                </label>
                <label>
                    <input type="checkbox" checked={showStartingItems} onChange={toggleStartingItems}/>
                    &nbsp; Starting Items Only
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={showArmor} onChange={() => toggleItemType(ITEM.ARMOR)}/>
                    Armor |&nbsp;
                </label>
                <label>
                    <input type="checkbox" checked={showConsumables} onChange={() => toggleItemType(ITEM.CONSUMABLES)}/>
                    Consumables |&nbsp;
                </label>
                <label>
                    <input type="checkbox" checked={showServices} onChange={() => toggleItemType(ITEM.SERVICES)}/>
                    Services |&nbsp;
                </label>
                <label>
                    <input type="checkbox" checked={showWeapons} onChange={() => toggleItemType(ITEM.WEAPONS)}/>
                    Weapons
                </label>
            </div>
            <span>
                <input id="search-bar" onKeyDown={handleKeyPress} style={{width:'75%'}}/>
                <button onClick={getItemShopData}>Search</button>
            </span>
        </center>
    );

    return (
        <div style={rulebook.content}>
            <h1>Item Shop</h1>
            {searchUI}
            {loadingItemShopItems && <h1>Loading items...</h1>}
            <ShopItems items={itemShopArray} shopType={ShopType.MOOLAH}/>
        </div>
    );
}

export default ItemShop;
