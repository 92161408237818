import React, { useEffect, useState } from "react";
import ShopItems, { ShopType } from "../Shops/shopItems";
import { getTalents } from "../Shops/XP Shop/xpShopService";
import { rulebook } from "../styling";

function FreeTalent() {
    const [talents, setTalents] = useState([]);

    async function fetchTalents() {
        setTalents(await getTalents(""));
    }

    useEffect(() => {
        fetchTalents();
    }, []);

    
    return (
        <>
            <h2>Pick a Free Talent</h2>
            <div style={rulebook.content}>
                <ShopItems items={talents} shopType={ShopType.STARTING_TALENT}/>
            </div>
        </>
    );
}

export default FreeTalent;
