import React, { useContext, useEffect, useState } from "react";
import { CreationContext } from "../Character Creation/creationContext";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { greyButton, greenButton, shop, ovoPallete } from "../styling";
import { motion, useAnimationControls } from "framer-motion";
import CharacterPanel from "../Character Creation/characterPanel";
import { SaveCharacter } from "../localContext";

export const ShopType = Object.freeze({
    DISPLAY_LIST : 0,
    STARTING_TALENT : 1,
    STARTING_SKILL : 2,
    STARTING_ITEMS : 3,
    MOOLAH : 4,
    XP : 5
});

export const ItemType = Object.freeze({
    ITEM : "Item",
    SKILL : "Skill",
    SKILLUP : "Skill Up!",
    TALENT : "Talent"
});

function ShopItems({items = [], shopType = ShopType.SEARCH}) {
const { creationContext, setCreationContext } = useContext(CreationContext);
    const [selectedItems, setSelectedItems] = useState([]);
    const [displaySelected, setDisplaySelected] = useState(<></>);
    const [infoToggles, setInfoToggles] = useState([]);
    const [shopNotification, setShopNotification] = useState("");
    const notificationAnimationControl = useAnimationControls();
    const selectedAnimationControl = useAnimationControls();
    const newNotificationAnimation = {duration: 5, y: [-25, 0]}
    const closeNotificationAnimation = {y: 250}

    useEffect(() => {
        SyncWithCurrentCharacter();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (creationContext.currentCharacterId === null)
            return;

        SaveCharacter(
            creationContext.currentCharacterId,
            creationContext.characterData
        );
    }, [creationContext.characterData, creationContext.currentCharacterId, items.length]);

    function ShopItemNotification(item, cost = 0, adding = true) {
        const message = `${adding ? "Added **" : "Removed **"}`
            .concat(item.name)
            .concat(adding ? "** to *" : "** from *")
            .concat(creationContext.characterData.name)
            .concat(shopType < 4 || !adding ? "*." : `* for`)
            .concat(`${item.type === ItemType.ITEM ? ` **ⓜ ${cost}**.` :  ` ** ${cost} XP**.`}`);
        
        setShopNotification(message);
        console.log(shopNotification);
        notificationAnimationControl.start(newNotificationAnimation);
    }

    function SyncWithCurrentCharacter() {
        if (shopType === ShopType.STARTING_TALENT) {
            setSelectedItems(
                creationContext.characterData.startingTalent === null ||
                creationContext.characterData.startingTalent === undefined ? [] : 
                [creationContext.characterData.startingTalent]
            );
        } else if (shopType === ShopType.STARTING_SKILL) {
            setSelectedItems(
                creationContext.characterData.startingSkill === null ||
                creationContext.characterData.startingSkill === undefined ? [] : 
                [creationContext.characterData.startingSkill]
            );
        } else if (shopType >= 4) {
            creationContext.currentCharacterId = null;
        }
    }

    useEffect(() => {
        if (shopType !== ShopType.SEARCH)
            UpdateCurrentCharacter();
        DisplaySelectedItems();
    }, [selectedItems.length]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        var toggleArray = infoToggles;
        items.forEach(() => {
            toggleArray.push(false);
        });

        setInfoToggles([...toggleArray]);
    }, [items.length]); // eslint-disable-line react-hooks/exhaustive-deps

    function UpdateCurrentCharacter() {
        if (shopType === ShopType.STARTING_TALENT) {
            setCreationContext({
                ...creationContext,
                characterData: {
                    ...creationContext.characterData,
                    startingTalent: selectedItems[0]
                },
            });
        } else if (shopType === ShopType.STARTING_SKILL) {
            setCreationContext({
                ...creationContext,
                characterData: {
                    ...creationContext.characterData,
                    startingSkill: selectedItems[0]
                }
            })
        }
    }

    function DisplaySelectedItems() {
        if (selectedItems === null || selectedItems === undefined)
            setSelectedItems([]);

        setDisplaySelected(selectedItems.map((item, index) => {
            return item === null ? <></> : (
                <>
                    <p style={{textIndent: "0px",
                        textAlign: "center", margin: "auto auto 10px auto",
                        float: "center", display: "block", width: "75%"}}>
                        {CurrencySymbol(item.type)} {item.cost} | {item.name}
                    </p>
                    <input type="button"
                        value="Remove" style={{
                            ...buttonStyle, margin: "10px auto", float: "none",
                            textAlign: "center", display: "flex", width: "25%"
                        }}
                        onClick={() => RemoveSelectedItem(index)}
                    />
                </>
            );
        }));

        selectedAnimationControl.start(() => {
            return {
                y: selectedItems.length > 0 ? 0 : -500,
                x: -350/2
            };
        });
    }

    const buttonStyle = {
        ...greenButton,
        display: "inline-block",
        width: "100px",
        float: "right",
        margin: "10px 5px auto auto",
        padding: "2px 5px 2px 5px",
        fontFamily: "Artifika",
        fontSize: "16px",
    }

    const disabledButtonStyle = {
        ...greyButton,
        display: "inline-block",
        borderWidth: "2px",
        width: "100px",
        float: "right",
        margin: "10px 5px auto auto",
        padding: "2px 5px 2px 5px",
        fontFamily: "Artifika",
        fontSize: "16px"
    }

    const itemButton = (item, shopType, isDiscounted = false, canAdd = false) => {
        const canAffordItem = CanAffordItem(isDiscounted ? item.cost/2 : item.cost);
        var buttonText = shopType === ShopType.MOOLAH ? "Buy" :
            canAdd ? "Learn" : "Known";

        return <input style={canAffordItem && canAdd ? buttonStyle : disabledButtonStyle}
            id={item.name}
            type="button"
            value={buttonText}
            onClick={() => {
                if (canAffordItem && canAdd) {
                    AddSelectedItem(item, isDiscounted);
                }
            }}
        />
    };

    function CanAffordItem(cost = 0) {
        if (shopType === ShopType.STARTING_TALENT || shopType === ShopType.STARTING_SKILL)
            return selectedItems.length === 0;

        if (shopType >= 4) {
            return cost <= (shopType === ShopType.XP ? 
                creationContext.characterData.xp :
                creationContext.characterData.moolah);
        }

        return false;
    }

    function AddSelectedItem(item, isDiscounted = false) {
        const cost = isDiscounted ? item.cost / 2 : item.cost;
        ShopItemNotification(item, cost);
        if (shopType < 4 )
            setSelectedItems([...selectedItems, item]);
        else {
            const newItemArray = shopType === ShopType.XP ? 
                item.type === ItemType.TALENT ? 
                    [...creationContext.characterData.talents, item] :
                    [...creationContext.characterData.skills, item] :
                [...creationContext.characterData.inventory, item];
            setCreationContext({
                ...creationContext,
                characterData: {
                    ...creationContext.characterData,
                    xp: shopType === ShopType.XP ?
                        creationContext.characterData.xp - cost :
                        creationContext.characterData.xp,
                    moolah: shopType === ShopType.MOOLAH ?
                        creationContext.characterData.moolah - cost :
                        creationContext.characterData.moolah,
                    talents: shopType === ShopType.XP && item.type === ItemType.TALENT ?
                        newItemArray :
                        creationContext.characterData.talents,
                    skills: shopType === ShopType.XP && item.type === ItemType.SKILL ?
                        newItemArray :
                        creationContext.characterData.skills,
                    inventory: shopType === ShopType.MOOLAH ?
                        newItemArray :
                        creationContext.characterData.inventory,
                    spentXp: shopType === ShopType.XP ? 
                        creationContext.characterData.spentXp + cost :
                        creationContext.characterData.spentXp
                }
            });
        }
    }

    function CanAddItem(item) {
        if (item === null || item === undefined)
            return false;

        if (item.type === ItemType.ITEM)
            return true;

        if (item.type === ItemType.TALENT) {
            return CanAddTalent(item);
        }

        if (item.type === ItemType.SKILL) {
            return CanAddSkill(item);
        }

        return false;
    }

    function CanAddSkill(skill) {
        if (creationContext.characterData === null || creationContext.characterData === undefined)
            return false;

        const alreadyKnown = () => {
            if (creationContext.characterData.startingSkill !== undefined && creationContext.characterData.startingSkill !== null)
                if (skill.name === creationContext.characterData.startingSkill.name)
                    return true;
    
            for (let i = 0; i < creationContext.characterData.skills.length; i++ ) {
                if (skill.name === creationContext.characterData.skills[i].name) {
                    return true;
                }
            }

            return false;
        }

        return !alreadyKnown();
    }

    function CanAddTalent(talent) {
        if (creationContext.characterData === null || creationContext.characterData === undefined)
            return false;

        const alreadyKnown = () => {
            if (creationContext.characterData.startingTalent !== undefined && creationContext.characterData.startingTalent !== null)
                if (talent.name === creationContext.characterData.startingTalent.name)
                    return true;
    
            for (let i = 0; i < creationContext.characterData.talents.length; i++ ) {
                if (talent.name === creationContext.characterData.talents[i].name) {
                    return true;
                }
            }

            return false;
        }

        return !alreadyKnown();
    }

    function RemoveSelectedItem(index) {
        var curentlySelected = selectedItems;
        const itemRemoved = curentlySelected.splice(index, 1);
        ShopItemNotification(itemRemoved, 0, false);
        setSelectedItems([...curentlySelected]);
    }

    function CurrencySymbol(type) {
        return type === ItemType.ITEM ? "ⓜ" : "XP";
    }

    function ToggleInfo(i) {
        var newTogglesArray = infoToggles;
        infoToggles[i] = !infoToggles[i];
        setInfoToggles([...newTogglesArray]);
    }

    function DiscountApplies(talents, tags) {
        for (let i = 0; i < talents.length; i++) {
            if (tags.includes(talents[i].tags[0]))
                return true;
        }

        return false;
    }

    const itemList = (items) => items.map((item, index) => {
        if (item === undefined || item === null)
            return <></>;

        var isDiscounted = false;

        if (shopType === ShopType.XP && item.type !== ItemType.TALENT) {
            if (creationContext.currentCharacterId !== null ) {
                var talents = [...creationContext.characterData.talents]
                talents.push(
                    creationContext.characterData.startingTalent
                );

                isDiscounted = DiscountApplies(talents, item.tags);
            }
                
        }

        const itemInfo = () => {
            if (item.type !== ItemType.SKILL) {
                return item.info.join();
            } else {
                return item.info.concat(
                    item.success.length === 0 ? [] :
                    ["\n###### **SUCCESS:**\n"].concat(item.success)
                ).concat(
                    item.snag.length === 0 ? [] :
                    ["\n###### **SNAG:**\n"].concat(item.snag)
                ).concat(
                    item.fail.length === 0 ? [] :
                    ["\n###### **FAIL:**\n"].concat(item.fail)
                ).join('\n');
            }
        };

        const displayedCost = isDiscounted ? item.cost / 2 : item.cost;

        return (
            <div style={{textAlign: "center"}}>
                <div key={`xp-item:${item.name}`}style={shop.item}>
                    <div style={{...shop.cost, color : isDiscounted ? ovoPallete.beakGreen : "black"}}>
                        {CurrencySymbol(item.type)} {displayedCost}
                    </div>
                    <div style={shop.name}>{item.name}</div>
                    <div style={shop.type}>{item.type}</div>
                    <br/>
                    {<div style={shop.tags}>{item.tags.join(", ")}</div>}
                    <br/>
                    <br/>
                    <div>
                        {shopType > 0 && creationContext.currentCharacterId !== null && itemButton(item, shopType, isDiscounted, CanAddItem(item))}
                        {creationContext.currentCharacterId !== null && item.type === ItemType.ITEM && <input type="button" 
                            style={buttonStyle}
                            onClick={() => console.log(`Added/Removed ${item.name}?`)}
                            value={shopType === ShopType.MOOLAH ? "Add" : "Remove"}
                         />}
                        <input type="button" style={buttonStyle} onClick={() => ToggleInfo(index)} value="Info"/>
                    </div>
                </div>
                {infoToggles[index] && <div style={shop.info}>
                    <Markdown className="markdown-content" remarkPlugins={[remarkGfm]}>
                        {itemInfo()}
                    </Markdown>
                </div>}
            </div> 
        );
    });

    const selectedStyle = {
        top: "1%",
        left: "50%",
        width: "350px",
        position: "fixed",
        display: "flex-box",
        border: "solid",
        borderWidth: "5px",
        borderColor: "black",
        backgroundColor: "white",
        padding: "-15px 15px 15px 15px"
    }

    const SelectedText = () => {
        if (shopType === ShopType.STARTING_TALENT)
            return "Starting Talent";
        else if (shopType === ShopType.STARTING_SKILL)
            return "Starting Skill";
        else if (shopType === ShopType.STARTING_ITEMS)
            return "Starting Items";
        else if (shopType === ShopType.XP)
            return "Abilities to Learn";
        else
            return "Items";
    }

    const notificationPanel = (
        <motion.div style={{
            position: "fixed",
            backgroundColor: ovoPallete.backgroundBeige,
            borderStyle: "dashed",
            borderColor: ovoPallete.beakGreen,
            width: "300px",
            margin: "10px auto",
            bottom: "25px",
            padding: "10px",
            textAlign: "center",
            right: "50px"
        }}
            transition={{type: "spring"}}
            initial={closeNotificationAnimation}
            animate={notificationAnimationControl}
        >
            <Markdown remarkPlugins={[remarkGfm]}> 
                {shopNotification}
            </Markdown>
            <input type="button"  style={{
                position: "absolute",
                top: "-10px",
                right: "-10px",
                borderStyle: "solid",
                borderColor: ovoPallete.beakGreen,
                backgroundColor: "white",
                borderRadius: "100%",
                width: "25px",
                height: "25px",
                color: ovoPallete.heartRed,
                fontWeight: "bolder",
                fontSize: "15px",
                userSelect: "none"
            }}
                value={"X"}
                onClick={() => notificationAnimationControl.start(closeNotificationAnimation)}
            />
        </motion.div>
    );

    return (
        <>
            {shopType > 3 && <CharacterPanel />}
            {shopType !== ShopType.SEARCH && 
                <motion.div style={selectedStyle}
                    animate={selectedAnimationControl}
                >
                    <h3>Selected {SelectedText()}</h3>
                    {displaySelected}
                </motion.div>
            }
            {itemList(items)}
            <br /><br /><br /><br /><br /><br />
            {notificationPanel}
        </>
    );
}

export default ShopItems;
