import React, { useCallback } from "react";
import { useState } from "react";
import { getSkills, getTalents } from "./xpShopService";
import { rulebook } from "../../styling";
import ShopItems, { ShopType } from "../shopItems";

function XPShop() {
    const [loadingShopItems, setLoadingShopItems] = useState(false);
    const [showTalents, setShowTalents] = useState(true);
    const [tier1, setTier1] = useState(true);
    const [tier2, setTier2] = useState(true);
    const [tier3, setTier3] = useState(true);
    const [tier4, setTier4] = useState(true);
    const [tier5, setTier5] = useState(true);
    const [talentArray, setTalentArray] = useState([]);
    const [skillArray, setSkillArray] = useState([]);

    const getXpShopData = useCallback(async () => {
        setLoadingShopItems(true);
        const textFilter = document.getElementById('search-bar').value;
        setTalentArray(showTalents ? await getTalents(textFilter) : []);
        let skillArrayResult = []
            .concat(tier1 ? await getSkills(textFilter, 1) : [])
            .concat(tier2 ? await getSkills(textFilter, 2) : [])
            .concat(tier3 ? await getSkills(textFilter, 3) : [])
            .concat(tier4 ? await getSkills(textFilter, 4) : [])
            .concat(tier5 ? await getSkills(textFilter, 5) : []);

        setSkillArray(skillArrayResult);
        setTimeout(() => setLoadingShopItems(false), 1000);
    }, [showTalents, tier1, tier2, tier3, tier4, tier5]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            getXpShopData();
        }
    }

    const filterUI = (
        <center>
            <div>
                <label>
                    Search All
                    <input type="checkbox"
                        checked={showTalents && tier1 && tier2 && tier3 && tier4 && tier5}
                        onChange={() => {
                            const shouldCheckAll = !showTalents;
                            setShowTalents(shouldCheckAll);
                            setTier1(shouldCheckAll);
                            setTier2(shouldCheckAll);
                            setTier3(shouldCheckAll);
                            setTier4(shouldCheckAll);
                            setTier5(shouldCheckAll);
                        }}
                    />
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={showTalents} onChange={() => setShowTalents(!showTalents)}/>
                    Talents
                </label>
                <br/>
                <label>
                    <input type="checkbox" checked={tier1} onChange={() => setTier1(!tier1)}/>
                    Tier 1 Skills |
                </label>
                <label>
                    <input type="checkbox" checked={tier2} onChange={() => setTier2(!tier2)}/>
                    Tier 2 Skills |
                </label>
                <label>
                    <input type="checkbox" checked={tier3} onChange={() => setTier3(!tier3)}/>
                    Tier 3 Skills |
                </label>
                <label>
                    <input type="checkbox" checked={tier4} onChange={() => setTier4(!tier4)}/>
                    Tier 4 Skills |
                </label>
                <label>
                    <input type="checkbox" checked={tier5} onChange={() => setTier5(!tier5)}/>
                    Tier 5 Skills
                </label>
            </div>
            <span>
                <input id="search-bar" onKeyDown={handleKeyPress} style={{width:'75%'}}/>
                <button onClick={getXpShopData}>Search</button>
            </span>
            <br />
        </center>
    );
    
    return (
        <div style={rulebook.content}>
            <h1>XP Shop</h1>
            <h3>Use the search bar and filters to look for Talents, & Skills!</h3>
            {filterUI}
            {loadingShopItems && <h1>Loading XP Item Search...</h1>}
            {<ShopItems items={talentArray.concat(skillArray)} shopType={ShopType.XP}/>}
        </div>
    );
}


export default XPShop;
