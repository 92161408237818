import { makeFilterArray, uriSource, strictMatch } from "../../adventumResolver";
import { detectEnvironment } from "../../environment";

export async function getTalents(filter) {
    const gitJsonPath = `${uriSource(detectEnvironment())}/XP%20Shop/Talents/Talents.json`;
    const filterArray = makeFilterArray(filter);
    return await fetch(gitJsonPath)
        .catch((error) => {
            console.log("(-v-') =>", error);
            return [];
        }).then(async (response) => {
            try {
                const text = response.text();
                const talents = JSON.parse(await text);
                const filteredTalents = filter === "" || filter === undefined ?
                    talents :
                    talents.filter(talent => fiterTalentThroughArray(talent, filterArray));
                return await filteredTalents;
            } catch (error) {
                console.log(error);
            }
            return [];
        }
        );
}

function fiterTalentThroughArray(talent, filterArray) {
    filterArray.sort();
    var matches = false;
    for (let i = 0; i < filterArray.length; i++) {
        if (filterArray[i][0] === '!') {
            const strictTerm = filterArray[i].slice(1);
            if (!seeIfTalentIncludes(talent, strictTerm, true))
                return false;
            else
                matches = true;
        } else if (seeIfTalentIncludes(talent, filterArray[i], false)) {
            matches = true;
        }
    }

    return matches;
}

function seeIfTalentIncludes(talent, filter, strict) {
    const caseInsensitiveFilter = filter.toLowerCase();
    return strict ? 
        strictMatch(filter, talent.cost.toString()) ||
        strictMatch(filter, talent.name) ||
        strictMatch(filter, talent.tag) ||
        strictMatch(filter, talent.info) : 
        talent.cost.toString().includes(caseInsensitiveFilter) ||
        talent.name.toLowerCase().includes(caseInsensitiveFilter) ||
        talent.tag.toLowerCase().includes(caseInsensitiveFilter) ||
        talent.info.toLowerCase().includes(caseInsensitiveFilter)
}

export async function getSkillUps(filter) {
    const gitJsonPath = `${uriSource(detectEnvironment())}/XP%20Shop/Skill%20Up!/Skill%20Up!.json`;
    const filterArray = makeFilterArray(filter);
    return await fetch(gitJsonPath)
        .catch((error) => {
            console.log("(-v-') =>", error);
            return [];
        }).then(async (response) => {
            try {
                const text = response.text();
                const skillUps = JSON.parse(await text);
                const filteredSkillUps = filter === "" || filter === undefined ?
                    skillUps :
                    skillUps.filter(skillUp => fiterSkillUpThroughArray(skillUp, filterArray));
                return await filteredSkillUps;
            } catch (error) {
                console.log(error);
            }
            return [];
        }
        );
}

function fiterSkillUpThroughArray(skillUp, filterArray) {
    filterArray.sort();
    var matches = false;
    for (let i = 0; i < filterArray.length; i++) {
        if (filterArray[i][0] === '!') {
            const strictTerm = filterArray[i].slice(1);
            if (!seeIfSkillUpIncludes(skillUp, strictTerm, true))
                return false;
            else
                matches = true;
        } else if (seeIfSkillUpIncludes(skillUp, filterArray[i], false)) {
            matches = true;
        }
    }

    return matches;
}

function seeIfSkillUpIncludes(skillUp, filter, strict) {
    const caseInsensitiveFilter = filter.toLowerCase();
    return strict ? 
        strictMatch(filter, skillUp.cost.toString()) ||
        strictMatch(filter, skillUp.increase.toLowerCase()) ||
        strictMatch(filter, skillUp.modifier.toString()) :
        skillUp.cost.toString().includes(caseInsensitiveFilter) ||
        skillUp.increase.toLowerCase().includes(caseInsensitiveFilter) ||
        skillUp.modifier.toString().includes(caseInsensitiveFilter)
}

export async function getSkills(filter, tier) {
    const gitJsonPath = `${uriSource(detectEnvironment())}/XP%20Shop/Skills/Tier%20${tier}.json`;
    const filterArray = makeFilterArray(filter);
    const skills = await fetch(gitJsonPath)
        .catch((error) => {
            console.log("(-v-') =>", error);
            return [];
        }).then(async (response) => {
            try {
                const text = response.text();
                const skills = JSON.parse(await text);
                const filteredSkills = filterArray.length === 0 || filterArray === undefined ?
                    skills :
                    skills.filter(skill => fiterSkillThroughArray(skill, filterArray));
                return await filteredSkills;
            } catch (error) {
                console.log(error);
            }
            return [];
        }
        );

    return await skills;
}

function fiterSkillThroughArray(skill, filterArray) {
    filterArray.sort();
    var matches = false;
    for (let i = 0; i < filterArray.length; i++) {
        if (filterArray[i][0] === '!') {
            const strictTerm = filterArray[i].slice(1);
            if (!seeIfSkillIncludes(skill, strictTerm, true))
                return false;
            else
                matches = true;
        } else if (seeIfSkillIncludes(skill, filterArray[i], false)) {
            matches = true;
        }
    }

    return matches;
}

function seeIfSkillIncludes(skill, filter, strict) {
    const caseInsensitiveFilter = filter.toLowerCase();
    return strict ?
        strictMatch(filter, skill.cost.toString()) ||
        strictMatch(filter, skill.name) ||
        strictMatch(filter, skill.tags.join()) ||
        strictMatch(filter, skill.info.join()) ||
        strictMatch(filter, skill.success.join()) ||
        strictMatch(filter, skill.snag.join()) ||
        strictMatch(filter, skill.fail.join()) :
        skill.cost.toString().includes(caseInsensitiveFilter) ||
        skill.name.toLowerCase().includes(caseInsensitiveFilter) ||
        skill.tags.join().toLowerCase().includes(caseInsensitiveFilter) ||
        skill.info.join().toLowerCase().includes(caseInsensitiveFilter) ||
        skill.success.join().toLowerCase().includes(caseInsensitiveFilter) ||
        skill.snag.join().toLowerCase().includes(caseInsensitiveFilter) ||
        skill.fail.join().toLowerCase().includes(caseInsensitiveFilter);
}
