const logoSize = "200px";
const sourceBookContentWidth = () => {
    const contentWidth = window.innerWidth < 900 ? "90%" : "50%";
    console.log(`${window.innerWidth} => ${contentWidth}`);
    return contentWidth;
}

export const ovoPallete = {
    beakGreen : "var(--beak-green)",
    evergreen : "var(--evergreen)",
    bookBlue : "var(--book-blue)",
    heartRed : "var(--heart-red)",
    borderBrown : "var(--border-brown)",
    backgroundBeige : "var(--background-beige)"
}


export const that = {
    logo: {
        width: logoSize,
        height: logoSize,
        marginTop: "35px",
        marginBottom: "0px",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex"
    },
    logoButton: {
        justifyContent: "center",
        margin: "auto",
        position: "fixed",
        width: "100px",
        height: "100px",
        left: "-52px",
        top: "50px",
        rotate: "270deg"
    },
    characterButton: {
        justifyContent: "center",
        margin: "auto",
        position: "fixed",
        width: "100px",
        height: "100px",
        right: "-52px",
        top: "50px",
        rotate: "90deg"
    }
}

export const adventum = {
    justifyContent: "center",
    display: "flex",
    margin: "auto",
    color: "black",
    fontSize: "75px",
    fontFamily: "Artifika"
}

const tableOfContentsChapter = (chapter, currentChapter) => {
    return {
        background: "none",
        color: ovoPallete.beakGreen,
        borderColor: ovoPallete.beakGreen,
        borderWidth: "3px",
        fontFamily: "Futura Heavy",
        fontSize: "28px",
        marginTop: "8px",
        marginLeft: "10px",
        borderRadius: "20px",
        textDecoration: chapter === currentChapter ? "underline" : "none"
    }
}

export const rulebook = {
    tableOfContentsTitle: {
        fontFamily: "Artifika",
        color: ovoPallete.bookBlue,
        fontSize: "36px",
        justifyContent: "center",
        margin: "10px auto"
    },
    tableOfContents: {
        backgroundColor: "white",
        borderColor: ovoPallete.borderBrown,
        borderStyle: "solid",
        borderWidth: "3px",
        width: "400px",
        position: "fixed",
        left: "-440px",
        top: "200px",
        padding: "15px",
        textAlign: "center"
    },
    chapter: tableOfContentsChapter,
    content: {
        justifyContent: "center",
        margin: "auto",
        border: "none",
        width: sourceBookContentWidth()
    },
    p: {
        fontFamily: "Arvo",
        fontSize: "16px",
        color: "black",
        textAlign: "left",
        lineHeight: "18px",
        textIndent: "40px"
    },
    h1: {
        fontFamily: "Artifika",
        fontSize: "48px",
        color: ovoPallete.evergreen,
        textAlign: "center",
        lineHeight: "60px",
        textIndent: "0px"
    },
    h2: {
        fontFamily: "Artifika",
        fontSize: "36px",
        color: ovoPallete.bookBlue,
        textAlign: "center",
        lineHeight: "50px",
        textIndent: "px"
    },
    h3: {
        fontFamily: "Artifika",
        fontSize: "30px",
        color: ovoPallete.heartRed,
        textAlign: "center",
        lineHeight: "40px",
        textIndent: "px"
    }
}

export const shop = {
    item: {
        border: "black",
        borderWidth: "2px",
        borderStyle: "solid",
        backgroundColor: "white",
        display: "inline-block",
        margin: "20px auto",
        width: "85%",
        padding: "15px"
    },
    cost: {
        display: "inline-block",
        color: "black",
        fontSize: "24px",
        float: "left",
        width: "75px",
        marginLeft: "5px"
    },
    type: {
        display: "inline-block",
        color: ovoPallete.bookBlue,
        fontSize: "24px",
        float: "right",
        width: "20%",
        marginRight: "5px",
        textAlign: "center"
    },
    name: {
        color: ovoPallete.evergreen,
        display: "inline-block",
        fontSize: "24px",
        width: "40%",
        margin: "auto",
        textAlign: "center"
    },
    tags: {
        display: "flex",
        color: ovoPallete.heartRed,
        fontSize: "16px",
        float: "left",
        width: "auto",
        height: "auto",
        margin: "15px 5px 5px auto",
        textAlign: "left",
        fontStyle: "italic"
    },
    info: {
        border: "black",
        borderWidth: "2px",
        borderStyle: "solid",
        backgroundColor: "white",
        width: "70%",
        margin: "-25px auto 20px auto",
        padding: "25px",
        minWidth: "10%",
        position: "relative",
        zIndex: "-100"
    },
    characterPanel: {
        backgroundColor: "white",
        borderColor: ovoPallete.borderBrown,
        borderStyle: "solid",
        borderWidth: "3px",
        width: "400px",
        position: "fixed",
        right: "40px",
        top: "200px",
        padding: "15px",
        textAlign: "center"
    }
}

export const table = {
    body: {
        border: "3px solid",
        width: "100%",
        borderCollapse: "collapse",
        tableLayout: "auto",
    },
    header: {
        border: "2px solid",
        textAlign: "center",
        fontSize: "24px"
    },
    data: {
        border: "2px solid",
        textAlign: "center",
        overflowWrap: "break-word"
    }
}

export const button = {
    enabled: {
        border: "solid",
        borderColor: ovoPallete.beakGreen,
        color: ovoPallete.beakGreen,
        backgroundColor: "rgba(1, 1, 1, 0)",
        fontFamily: "Artifika"
    },
    disabled: {
        border: "solid",
        borderColor: "#787878",
        color: "white",
        backgroundColor: "#ababab",
        fontFamily: "Artifika"
    },
    whileHover: {
        color: "#FFFFFF",
        backgroundColor: ovoPallete.beakGreen
    },
    redEnabled: {
        border: "solid",
        borderColor: ovoPallete.heartRed,
        color: ovoPallete.heartRed,
        backgroundColor: "rgba(1, 1, 1, 0)",
        fontFamily: "Artifika"
    },
    redWhileHover: {
        color: "#FFFFFF",
        backgroundColor: ovoPallete.heartRed
    }
}

export const greenButton = {
    border: "solid",
    borderWidth: "3px",
    borderColor: ovoPallete.beakGreen,
    color: ovoPallete.beakGreen,
    backgroundColor: "rgba(1, 1, 1, 0)",
    fontFamily: "Artifika"
}

export const greyButton = {
    border: "solid",
    borderColor: "#787878",
    color: "white",
    backgroundColor: "#ababab",
    fontFamily: "Artifika"
}

export const code = {
    color: "white",
    backgroundColor: "black",
    padding: "5px"
}
