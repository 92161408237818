import React, { useContext, useEffect, useMemo, useState } from "react";
import AdventumMarkdown from "../Adventum Markdown/adventumMarkdown";
import NameAndDescription from "./nameAndDescription";
import { motion, useAnimationControls } from "framer-motion";
import { getChapterMarkdown } from "../adventumResolver";
import CharacterSelection from "./characterSelection";
import { CreationContext } from "./creationContext";
import { GetCharacterData, GetCharacterIds, SaveCharacter } from "../localContext";
import { ENVIRONMENT, detectEnvironment } from "../environment";
import FreeTalent from "./freeTalent";
import FreeSkill from "./freeSkill";
import StatManipulation from "./statManipulation";
import Motivation from "./motivation";
import DisplayCharacter from "./displayCharacter";

export function CharacterCreation() {
    const [showMarkdown, setShowMarkdown] = useState(-1);
    const [chapterMarkdown, setChapterMarkdown] = useState("# Loading Chapter Content...");
    const { creationContext, setCreationContext } = useContext(CreationContext);
    const [creationPage, setCreationPage] = useState(<CharacterSelection />);
    const chevronAnimationControl = useAnimationControls();
    const textBoxAnimationControl = useAnimationControls();
    const leftNavAnimationControl = useAnimationControls();
    const rightNavAnimationControl = useAnimationControls();
    var devIdLookup = "";
    
    useEffect(() => {
        async function getMarkdown() {
            setChapterMarkdown(await getChapterMarkdown("Character Creation"));
        }
        getMarkdown();
        setCreationContext({
            ...creationContext,
            creationStep: 0
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        const toggleAnimation = () => { return {rotate:showMarkdown ? 0 : -90} };
        const toggleTextBoxAnimation = () => { return {width:showMarkdown ? "80%" : "40%"} }
        chevronAnimationControl.start(toggleAnimation());
        textBoxAnimationControl.start(toggleTextBoxAnimation());
    }, [showMarkdown, chevronAnimationControl, textBoxAnimationControl]);
    
    const characterCreationSteps = useMemo(() => [
        <CharacterSelection />,
        <NameAndDescription />,
        <FreeTalent />,
        <FreeSkill />,
        <StatManipulation />,
        <Motivation />,
        <DisplayCharacter />
    ], []);
    
    useEffect(() => {
        setCreationPage(characterCreationSteps[creationContext.creationStep]);
        leftNavAnimationControl.start(() => {
            return {x : creationContext.creationStep === 0 || creationContext.creationStep === 6 ? -300 : 0}
        });
        rightNavAnimationControl.start(() => {
            return {x : creationContext.creationStep === 0 || creationContext.creationStep === 6 ? 300 : 0}
        })
        
    }, [creationContext.creationStep, leftNavAnimationControl, rightNavAnimationControl, characterCreationSteps]);
    
    const markdownBox = {
        borderStyle: "solid",
        borderColor: "black",
        borderWidth: "3px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "25px",
        background: "#FFFFFF",
        padding: "5px 15px 5px 15px"
    };
    
    const chapterText = (
        <motion.div style={markdownBox} animate={textBoxAnimationControl}>
            <div style={{display:"flex"}}>
                <h3 style={{marginLeft:"20px"}}>Rules</h3>
                <motion.img
                    src="chevron-arrow-down.png" style={{
                        border:"none",
                        margin:"auto",
                        marginRight: "5px",
                        width:"45px",
                        height:"45px"
                    }} 
                    initial={() => setShowMarkdown(false)}
                    animate={chevronAnimationControl}
                    onClick={() => setShowMarkdown(!showMarkdown)}
                    />
            </div>
            {showMarkdown && <AdventumMarkdown style={{margin:"auto"}} markdown={chapterMarkdown}/>}
        </motion.div>
    );
    
    function incrementStep(amount) {
        var newLocation = creationContext.creationStep + amount;
        newLocation = newLocation < 0 || newLocation >= characterCreationSteps.length ? 
            creationContext.creationStep :
            newLocation;
            
        setCreationContext({...creationContext, creationStep:newLocation});

        if (newLocation - amount !== 0) {
            SaveCharacter(creationContext.currentCharacterId, creationContext.characterData);
        }
    }
        
    const creationNavigation = (
        <div style={{display:"flex"}}>
            <motion.img
                src="chevron-arrow-down.png"
                style={{
                    position:"fixed",
                    top: "60vh",
                    left: "1vw",
                    border:"none",
                    width:"35px",
                    height:"35px"
                }}
                initial={{rotate:90}}
                animate={leftNavAnimationControl}
                whileHover={{scale:1.25}}
                whileTap={{scale:0.9}}
                onClick={() => incrementStep(-1)}/>
            <motion.img
                src="chevron-arrow-down.png"
                style={{
                    position:"fixed",
                    top: "60vh",
                    right:"1vw",
                    border:"none",
                    width:"35px",
                    height:"35px"
                }} 
                initial={{rotate:-90}}
                animate={rightNavAnimationControl}
                whileHover={{scale:1.25}}
                whileTap={{scale:0.9}}
                onClick={() => incrementStep(1)}/>
        </div>
    );

    const debugButtons = (
        <>
        <button onClick={() => {
                console.log(creationContext);
                }}>
                Print Creation Context
            </button>
            <br/>
            <button onClick={() => {
                console.log(creationContext.currentCharacterId);
                console.log(creationContext.characterData);
                }}>
                Print Context Character Data
            </button>
            <br/>
            <button onClick={() => {
                console.log(GetCharacterIds());
                }}>
                Print Local Storage Character IDs
            </button>
            <br/>
            <input type="text" onChange={(e) => devIdLookup = e.target.value}/>
            <button onClick={() => {
                console.log(GetCharacterData(devIdLookup));
                }}>
                Print Local Storage Character
            </button>
            <br/>
            <button onClick={() => {localStorage.clear()}}>
                Clear Local Storage
            </button>
        </>
    );
    
    return (
        <>
            {creationPage}
            {creationNavigation}
            {chapterText}
            {detectEnvironment() === ENVIRONMENT.DEV && debugButtons}
        </>
    );
}
