import React, { useEffect, useState }  from "react";
import { motion } from "framer-motion";
import TableOfContents from "../Table of Contents/tableOfContents";
import { retrievePageFromPath } from "../adventumResolver";
import { useLocation } from "react-router-dom";

function SourceBook() {
    const [loadingChapterContent, setLoadingChapterContent] = useState(true);
    const [chapterContent, setChapterContent] = useState("");
    const location = useLocation();

    useEffect(() => {
        const fetchChapterContent = async () => {
            setLoadingChapterContent(true);
            const path = location.pathname.length < 2 ? "/intro" : location.pathname;
            setChapterContent(await retrievePageFromPath(path));
            setTimeout(() => setLoadingChapterContent(false), 1000);
            return;
        };
        fetchChapterContent();
        // eslint-disable-next-line
    }, [location]);

    return (
        <motion.div>
            {loadingChapterContent && <h1>Loading sourcebook...</h1>}
            {!loadingChapterContent && chapterContent}
            <TableOfContents />
        </motion.div>
    );
}

export default SourceBook;
