import React, { useState }  from "react";
import { motion } from "framer-motion";
import { chapters, getPathFrom } from "../adventumResolver";
import { Link } from "react-router-dom";
import { that, rulebook } from "../styling";

export default function TableOfContents() {
    const [showingTable, setShowingTable] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [currentChapter, setCurrentChapter] = useState("Introduction");

    function toggleTableOfContents() {
        setShowingTable(!showingTable);
        setButtonClicked(true);
        setTimeout(() => {
            setButtonClicked(false);
        }, 100);
    }

    function selectedChapter(chapter) {
        setCurrentChapter(chapter)
        toggleTableOfContents();
    }

    const chapterElements = chapters.map(chapter =>
        <motion.div whileHover={{scale:1.25}} animate={{scale: chapter === currentChapter ? 1.25 : 1}}>
            <Link to={getPathFrom(chapter)} onClick={() => selectedChapter(chapter)}
                style={rulebook.chapter(chapter, currentChapter)}>
                {chapter}
            </Link>
        </motion.div>
    );

    return (
        <>
            <motion.img style={that.logoButton} src="Beak Button (Beak Green).png" 
                    draggable={false}
                    initial={{y: 18}}
                    transition={{type: "tween"}}
                    whileHover={{x: 23, borderWidth: 3}}
                    animate={{
                        x: 0,
                        scale: buttonClicked ? 0.75 : 1
                    }}
                onClick={() => toggleTableOfContents()}/>
            <motion.div style={rulebook.tableOfContents} transition={{type:"spring"}} initial={{x:0}} animate={{x: showingTable ? 510 : 0}}>
                <div style={rulebook.tableOfContentsTitle}>Table of Contents</div>
                <div>{chapterElements}</div>
            </motion.div>
        </>
    );
}
