import React, { useEffect, useContext } from "react";
import { ovoPallete, rulebook } from "../styling";
import { CreationContext } from "./creationContext";
import { DefaultCharacter } from "../localContext";

function NameAndDescription() {
    const {creationContext, setCreationContext} = useContext(CreationContext);

    useEffect(() => {
        if (creationContext.currentCharacterId === null)
            setCreationContext({
                ...creationContext,
                characterData: {...DefaultCharacter},
                currentCharacterId: Date.now()
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const nameInputStyle = {
        display: "flex",
        margin: "0px auto 10px auto",
        borderWidth: "5px",
        borderColor: ovoPallete.beakGreen,
        borderStyle: "solid",
        width: "75%",
        height: "35px",
        fontSize: "24px",
        fontFamily: "Arvo",
        textAlign: "center"
    }

    function SetName(newName) {
        setCreationContext({
            ...creationContext,
            characterData: {
                ...creationContext.characterData,
                name: newName
            } 
        });
    }

    function SetDescription(newDescription) { 
        setCreationContext({
            ...creationContext,
            characterData: {
                ...creationContext.characterData,
                description: newDescription
            }
        });
    }

    return (
        <div style={rulebook.content}>
            <h2>Name</h2>
            <input type="text" style={nameInputStyle}
                onChange={e => SetName(e.target.value)}
                value={creationContext.characterData.name}
            />
            <h2>Description</h2>
            <textarea type="text" style={textAreaStyle}
                onInput={e => SetDescription(e.target.value)}
                defaultValue={creationContext.characterData.description}
            />
        </div>
    );
}

export default NameAndDescription;

export const textAreaStyle = {
    margin: "auto",
    display: "block",
    borderWidth: "5px",
    borderColor: ovoPallete.beakGreen,
    width: "80%",
    height: "75px",
    resize: "none",
    fontFamily: "Arvo",
    fontSize: "14px",
    padding: "10px"
}
