import React, { useState } from "react";
import ShopItems, { ShopType } from "../Shops/shopItems";
import { getSkills } from "../Shops/XP Shop/xpShopService";
import { rulebook } from "../styling";

function FreeSkill() {
    const [skills, setSkills] = useState([]);
    const [tier1, setTier1] = useState(true);
    const [tier2, setTier2] = useState(true);
    const [tier3, setTier3] = useState(true);
    const [tier4, setTier4] = useState(true);
    const [textFilter, setTextFilter] = useState("");

    async function fetchSkills() {
        setSkills([]
            .concat(tier1 ? await getSkills(textFilter, 1) : [])
            .concat(tier2 ? await getSkills(textFilter, 2) : [])
            .concat(tier3 ? await getSkills(textFilter, 3) : [])
            .concat(tier4 ? await getSkills(textFilter, 4) : [])
        );
    }
    
    return (
        <center>
            <h2>Pick a Free Skill</h2>
            <div style={rulebook.content}>
                <div>
                    <label>
                        <input type="checkbox" checked={tier1} onChange={() => setTier1(!tier1)}/>
                        Tier 1 Skills |
                    </label>
                    <label>
                        <input type="checkbox" checked={tier2} onChange={() => setTier2(!tier2)}/>
                        Tier 2 Skills |
                    </label>
                    <label>
                        <input type="checkbox" checked={tier3} onChange={() => setTier3(!tier3)}/>
                        Tier 3 Skills |
                    </label>
                    <label>
                        <input type="checkbox" checked={tier4} onChange={() => setTier4(!tier4)}/>
                        Tier 4 Skills
                    </label>
                </div>
                <input type="text" onChange={(e) => setTextFilter(e.target.value)} style={{width:'50%'}}/>
                <br/>
                <input type="button" value="Search Skills" onClick={() => fetchSkills()}/>
                <ShopItems items={skills} shopType={ShopType.STARTING_SKILL}/>
            </div>
        </center>
    );
}

export default FreeSkill;