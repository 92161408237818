import React, { useEffect, useContext, useState } from "react";
import { rulebook } from "../styling";
import { CreationContext } from "./creationContext";
import { textAreaStyle } from "./nameAndDescription";

function Motivation() {
    const {creationContext, setCreationContext} = useContext(CreationContext);
    const [motivation, setMotivation] = useState("");

    useEffect(() => {
        setMotivation(creationContext.characterData.motivation);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCreationContext({
            ...creationContext,
            characterData: {
                ...creationContext.characterData,
                motivation
            }
        })
    }, [motivation]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={rulebook.content}>
            <center style={{fontFamily: "Arvo", fontSize: "20px", margin:"auto auto -20px auto"}}>
                And finally...
            </center>
            <h2>Your motivation!</h2>
            <textarea
                style={textAreaStyle}
                onChange={(e) => setMotivation(e.target.value)}
                defaultValue={motivation}/>
        </div>
    );
}

export default Motivation;
