import { makeFilterArray, uriSource, strictMatch } from "../../adventumResolver";
import { detectEnvironment } from "../../environment";

export const ITEM = Object.freeze({
    STARTING : "Starting",
    WEAPONS : "Weapons",
    ARMOR : "Armor",
    CONSUMABLES : "Consumables",
    SERVICES : "Services"
});

export async function getItemsByType(filter, type) {
    const gitJsonPath = `${uriSource(detectEnvironment())}/Item%20Shop/${type}.json`;
    const filterArray = makeFilterArray(filter);
    
    const items = await fetch(gitJsonPath)
        .catch((error) => {
            console.log("(-v- ) =>", error);
            return [];
        }).then(async (response) => {
            try {
                const text = response.text();
                const items = JSON.parse(await text);
                const filteredItems = filter === "" || filter === undefined ?
                    items :
                    items.filter(item => filterItemThroughArray(item, filterArray));
    
                return await filteredItems;
            } catch (error) {
                console.log(error);
            }
            return [];
        });

    return await items;
}

function filterItemThroughArray(item, filterArray) {
    filterArray.sort();
    var matches = false;
    for (let i = 0; i < filterArray.length; i++) {
        if (filterArray[i][0] === '!') {
            const strictTerm = filterArray[i].slice(1);
            if (!seeIfItemIncludes(item, strictTerm, true))
                return false;
            else
                matches = true;
        } else if (seeIfItemIncludes(item, filterArray[i], false)) {
            matches = true;
        }
    }

    return matches;
}

function seeIfItemIncludes(item, filter, strict) {
    const caseInsensitiveFilter = filter.toLowerCase();
    return strict ?
        strictMatch(filter, item.name) ||
        strictMatch(filter, item.cost.toString()) ||
        strictMatch(filter, item.tags.join()) ||
        strictMatch(filter, item.info.join()) :
        item.name.toLowerCase().includes(caseInsensitiveFilter) ||
        item.cost.toString().includes(caseInsensitiveFilter) ||
        item.tags.join().toLowerCase().includes(caseInsensitiveFilter) ||
        item.info.join().toLowerCase().includes(caseInsensitiveFilter);
}
