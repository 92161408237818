import { useLayoutEffect, useRef } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

function AdventumMarkdown({markdown}) {
    const markdownRef = useRef();

    useLayoutEffect(() => {
        replaceEscapes();
        fixListElements();
    });

    function replaceEscapes() {
        var markdownBody = markdownRef.current;
        markdownBody.innerHTML = markdownBody.innerHTML.replaceAll("\\n", "<br/>");
    }
    
    function fixListElements() {
        var paragraphs = markdownRef.current.getElementsByTagName("p");
        Array.from(paragraphs).forEach((paragraph) => {
            if (paragraph.parentElement.tagName === "LI") {
                var paragraphContents = paragraph.innerHTML;
                paragraph.parentElement.innerHTML = paragraphContents;
            }
        });
    }

    const generatedHTML = (
        <Markdown className="markdown-content" remarkPlugins={[remarkGfm]}>
            {markdown}
        </Markdown>
    );

    return (
        <div ref={markdownRef} className="markdown-contet">
            {generatedHTML}
        </div>
    );
}

export default AdventumMarkdown;