import React, { useEffect, useState, useContext } from "react";
import { greenButton, greyButton } from "../styling";
import { CreationContext } from "./creationContext";

function StatManipulation() {
    const {creationContext, setCreationContext} = useContext(CreationContext);
    const [statPoints, setStatPoints] = useState(0);
    const [clever, setClever] = useState(0);
    const [dextrous, setDextrous] = useState(0);
    const [graceful, setGraceful] = useState(0);
    const [irreverent, setIrreverent] = useState(0);
    const [magical, setMagical] = useState(0);
    const [martial, setMartial] = useState(0);
    const [natural, setNatural] = useState(0);
    const [strong, setStrong] = useState(0);

    useEffect(() => {
        setClever(creationContext.characterData.clever.value);
        setDextrous(creationContext.characterData.dextrous.value);
        setGraceful(creationContext.characterData.graceful.value);
        setIrreverent(creationContext.characterData.irreverent.value);
        setMagical(creationContext.characterData.magical.value);
        setMartial(creationContext.characterData.martial.value);
        setNatural(creationContext.characterData.natural.value);
        setStrong(creationContext.characterData.strong.value);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCreationContext({
            ...creationContext,
            characterData: {
                ...creationContext.characterData,
                clever: {
                    ...creationContext.characterData.clever,
                    value: clever
                },
                dextrous: {
                    ...creationContext.characterData.dextrous,
                    value: dextrous
                },
                graceful: {
                    ...creationContext.characterData.graceful,
                    value: graceful
                },
                irreverent: {
                    ...creationContext.characterData.irreverent,
                    value: irreverent
                },
                magical: {
                    ...creationContext.characterData.magical,
                    value: magical
                },
                martial: {
                    ...creationContext.characterData.martial,
                    value: martial
                },
                natural: {
                    ...creationContext.characterData.natural,
                    value: natural
                },
                strong: {
                    ...creationContext.characterData.strong,
                    value: strong
                },
            }
        });
    }, [clever, dextrous, graceful, irreverent, magical, martial, natural, strong]); // eslint-disable-line react-hooks/exhaustive-deps

    const statBlockStyle = {
        fontFamily: "Artifika",
        textAlign: "center"
    }

    const manipButton = {
        ...greenButton,
        display: "inline-block", 
        fontSize: "30px",
        width: "40px",
        height: "40px",
        backgroundColor: "white",
        alignSelf: "flex-middle"
    }

    const disabledManipButton= {
        ...greyButton,
        display: "inline-block", 
        fontSize: "30px",
        padding: "0px",
        width: "40px",
        height: "40px",
        alignSelf: "flex-middle"
    }

    const statStyle = {
        fontSize: "24px",
        display:"inline-block", 
        padding: "3px",
        alignSelf: "flex-middle",
        marginLeft: "5px",
        marginRight: "5px",
        border: "solid",
        borderColor: "black",
        borderWidth: "3px",
        backgroundColor: "white",
        width: "180px",
        transform: "translate(0px, -3px)"
    }

    const buttonStyle = (stat, adding = true) => {
        if (!adding)
            return CanSubtract(stat) ? manipButton : disabledManipButton;

        return CanAdd(stat) ? manipButton : disabledManipButton;
    }

    function SetStat(stat, setStat, amount) {
        const newStat = stat + amount;
        setStatPoints(statPoints-amount);
        setStat(newStat);
    }

    function CanAdd(stat) {
        return stat < 2 && statPoints > 0;
    }

    function CanSubtract(stat) {
        return stat > -4;
    }

    const statBlock = (name, getStat, setStat) => {
        return (
            <div style={{margin: "10px"}}>
                <input
                    type="button"
                    style={buttonStyle(getStat, false)}
                    value="-"
                    onClick={() => {
                        if (CanSubtract(getStat)) {
                            SetStat(getStat, setStat, -1);
                        }
                    }}
                />
                <div style={statStyle}>
                    {name} : {getStat}
                </div>
                <input
                    type="button"
                    style={buttonStyle(getStat)}
                    value="+"
                    onClick={() => {
                        if (CanAdd(getStat)) {
                            SetStat(getStat, setStat, 1);
                        }
                    }}
                />
            </div>
        );
    };

    return (
        <div>
            <h2>Manipulate Your Base Stats</h2>
            <h3>{statPoints} points</h3>
            <div style={statBlockStyle}>
                {statBlock("Clever", clever, setClever)}
                {statBlock("Dextrous", dextrous, setDextrous)}
                {statBlock("Graceful", graceful, setGraceful)}
                {statBlock("Irreverent", irreverent, setIrreverent)}
                {statBlock("Magical", magical, setMagical)}
                {statBlock("Martial", martial, setMartial)}
                {statBlock("Natural", natural, setNatural)}
                {statBlock("Strong", strong, setStrong)}
            </div>
        </div>
    );
}

export default StatManipulation;
