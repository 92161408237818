import React from "react";
import { detectEnvironment, ENVIRONMENT } from "./environment";
import AdventumMarkdown from "./Adventum Markdown/adventumMarkdown";
import XPShop from "./Shops/XP Shop/xpShop";
import ItemShop from "./Shops/Item Shop/itemShop";
import { rulebook, code } from "./styling";
import { CharacterCreation } from "./Character Creation/characterCreation";

export const chapters = [
    "Introduction",
    "Don'ts & Do's",
    "World Building",
    "Plot & People",
    "Rolling",
    "Death's Door",
    "Character Creation",
    "Relationships",
    "XP Shop",
    "Item Shop",
    "Alchemy",
    "Enchanting",
    "Spellcraft",
    "End of Session"
];

const paths = [
    "/intro",
    "/donts-and-dos",
    "/worldbuilding",
    "/plot-and-people",
    "/rolling",
    "/deaths-door",
    "/character-creation",
    "/relationships",
    "/xp-shop",
    "/item-shop",
    "/alchemy",
    "/enchanting",
    "/spellcraft",
    "/end-of-session"
];

export const uriSource = (environment) => {
    return environment === ENVIRONMENT.DEV ?
    `http://localhost:8080` : environment === ENVIRONMENT.SANDBOX ? 
    `https://adventum-markdown-sandbox.ovoguy.com` :
    `https://raw.githubusercontent.com/ThatOwlGuy/adventum.markdown-ruleset/prod`;
}

export function getPathFrom(chapter) {
    const resourceIndex = chapters.indexOf(chapter);
    return paths[resourceIndex];
}

export function getChapterFrom(path) {
    const resourceIndex = paths.indexOf(path);
    return chapters[resourceIndex];
}

export async function retrievePageFromPath(path) {
    const chapter = getChapterFrom(path);
    try {
        if (chapter === 'Character Creation') {
            return (<CharacterCreation />);
        } else if (chapter === 'XP Shop') {
            return (<XPShop />);
        } else if (chapter === 'Item Shop') {
            return (<ItemShop />);
        } else {
            const markdown = getChapterMarkdown(chapter);
            return (
                <div style={rulebook.content}>
                    <AdventumMarkdown markdown={await markdown}/>
                </div>
            );
        }
    } catch (error) {
        console.log('-v-...\n',error);
        return (
            <>
                <h3>Sorry, we couldn't fetch any data from the sourcebook.</h3>
                {detectEnvironment() === ENVIRONMENT.SANDBOX && reportBugsPlease}
                {detectEnvironment() === ENVIRONMENT.DEV && devDialogue}
            </>
        );
    }
}

export async function getChapterMarkdown(chapter) {
    const gitDownPath = `${uriSource(detectEnvironment())}/Core%20Ruleset/${chapter}.md`;
    return await fetch(gitDownPath).then(async (markdown) => await (markdown).text());
}

const reportBugsPlease = (
    <>
        <p>If you are a playtester, please let us know about this (and any other issues) through the bug report form linked below.</p>
        <h3>We don't currently have a bug report form -v-... Message me @ThatOwlGuy on Discord instead!</h3>
    </>
);

const devDialogue = (
    <>
        <h2>Please check to make sure you have done the following:</h2>
        <h3>If you are fetching the sourcebook from prod, Check to make sure the endpoints in the resolver are correct.</h3>
        <p>If not, make sure to report or begin remediating the issue in production.</p>
        <h3>If you are fetching the sourcebook from a branch of the repo:</h3>
        <p>We should be switching to pulling from the web server instead of the repo soon.</p>
        <p>Instead of sourcing directly from the repo, server the files locally and pull the bracnh you want instead.</p>
        <h3>If you are fetching from the markdown being served locally make sure:</h3>
        <ul>
            <li>
                That you are locally serving the 
                &nbsp;<a href="https://github.com/ThatOwlGuy/adventum.markdown-ruleset">markdown repository</a>.
                <p>run <code style={code}>npx http-server --cors</code> in your markdown directory to serve the adventum rules.</p>
            </li>
            <li>
                That your endpoint and local server are pointed to port&nbsp;<a href="http://localhost:8080">8080</a>
            </li>
            <li>
                That you're not sending the wrong environment to <code style={code}>uriSource()</code>
            </li>
        </ul>
    </>
)

export function makeFilterArray(filterString) {
    if (filterString.length === 0 || filterString === undefined || filterString === null)
        return [];
    
    const tokenArray = filterString.match(/(".*"|[^\W_]+[^\s-]*)/g);
    if (tokenArray === null)
        return [];
    
    var filterArray = [];

    tokenArray.forEach((filterToken) => {
        if (filterToken[0] === '"' && filterToken[filterToken.length-1] === filterToken[0])
            filterToken = `!${filterToken.replaceAll('"', '')}`;
        filterArray.push(filterToken);
    });
    
    return filterArray;
}

export function strictMatch(matchTerm, textToSearch) {
    if (matchTerm.includes(" ")) {
        return textToSearch.includes(matchTerm);
    }

    const textToSearchArray = textToSearch.split(" ");
    for (let i = 0; i < textToSearchArray.length; i++) {
        if (matchTerm === textToSearchArray[i]) {
            return true;
        }
    };

    return false;
}
