import React, { useState, useContext, useEffect } from "react";
import { GetCharacterData, GetCharacterIds, DefaultCharacter, DeleteCharacter } from "../localContext";
import { ovoPallete, button } from "../styling";
import { motion } from "framer-motion";
import { CreationContext } from "./creationContext";

function CharacterSelection() {
    const { creationContext, setCreationContext } = useContext(CreationContext);
    const [characterIdList, setCharacterIdList] = useState(GetCharacterIds());
    const [characterDisplay, setCharacterDisplay] = useState(<></>);

    useEffect(() => {
        setCharacterIdList(GetCharacterIds());
    }, []);

    useEffect(() => {
        function DisplayCharacter(id) {
            const characterData = GetCharacterData(id);
            setCreationContext({
                ...creationContext,
                currentCharacterId: id,
                characterData,
                creationStep: 6
            });
        }

        const characterButtons = (id, name) => {
            return <div style={{display: "flex", width: "450px", margin: "auto"}}>
                <motion.input type="button" style={{
                    display: "inline-block",
                    borderStyle: "solid",
                    borderColor: ovoPallete.beakGreen,
                    borderWidth: "3px",
                    width: "85%",
                    margin: "5px auto 25px auto",
                    padding: "5px",
                    fontFamily: "Artifika",
                    fontSize: "20px",
                    fontWeight: "bold",
                    backgroundColor: ovoPallete.backgroundBeige,
                    color: ovoPallete.beakGreen
                }}
                    value={name}
                    whileHover={button.whileHover}
                    onClick={() => DisplayCharacter(id)}
                />
                <motion.input type="button"style={{
                    display: "inline-block",
                    borderStyle: "solid",
                    borderColor: ovoPallete.heartRed,
                    borderWidth: "3px",
                    width: "10%",
                    margin: "5px auto 25px auto",
                    padding: "5px",
                    fontFamily: "Artifika",
                    fontSize: "20px",
                    fontWeight: "bold",
                    backgroundColor: ovoPallete.backgroundBeige,
                    color: ovoPallete.heartRed
                }}
                    value="X"
                    whileHover={button.redWhileHover}
                    onClick={() => {
                        DeleteCharacter(id);
                        setCharacterIdList(GetCharacterIds());
                    }}
                />
            </div>
        };

        setCharacterDisplay(
            <div style={{margin:"30px auto auto auto"}}>
                {characterIdList.length > 0 && <h2>Your Characters</h2>}
                {characterIdList.map((id) => {
                    const characterData = GetCharacterData(id);
                    if (characterData === null || characterData === undefined) return <></>;
                    
                    return (
                        <div style={{textAlign:"center"}}>
                            {characterButtons(id, characterData.name)}
                            <br />
                        </div>
                    );
                })}
            </div>
        );
    }, [characterIdList]); // eslint-disable-line react-hooks/exhaustive-deps

    const newCharacterButton = {
        display: "flex",
        backgroundColor: ovoPallete.backgroundBeige,
        color: ovoPallete.beakGreen,
        borderColor: ovoPallete.beakGreen,
        margin: "auto",
        fontSize: "32px",
        padding: "10px",
        fontFamily: "Artifika",
        fontWeight: "bold",
        borderWidth: "5px",
        borderStyle: "solid"
    };

    function StartCharacterCreation() {
        setCreationContext({
            ...creationContext, 
            characterData: {...DefaultCharacter},
            currentCharacterId: Date.now(),
            creationStep: 1});
    }

    return (
        <>
            <h1>Characters</h1>
            <div style={{margin: "auto", display:"flex"}}>
                <motion.input type="button" style={newCharacterButton}
                    onClick={() => StartCharacterCreation()}
                    value="Create New Character"
                    initial={{x: 50}}
                    animate={{
                        x: 0,
                        transition: {duration: 0.25},
                        color: ovoPallete.beakGreen,
                        backgroundColor: ovoPallete.backgroundBeige
                    }}
                    whileHover={button.whileHover}
                    />
            </div>
            {characterIdList.length > 0 && characterDisplay}
        </>
    );
}

export default CharacterSelection;
