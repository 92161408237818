import React, {useContext, useEffect, useState} from "react";
import { CreationContext } from "./creationContext";
import { motion } from "framer-motion";
import { that, rulebook, shop, button, ovoPallete } from "../styling";
import { GetCharacterIds, GetCharacterData } from "../localContext";

function CharacterPanel() {
    const {creationContext, setCreationContext} = useContext(CreationContext);
    const [characterButtonClicked, setCharacterButtonClicked] = useState(false);
    const [showingCharacterPanel, setShowingCharacterPanel] = useState(false);
    const [showCharacterInfo, setShowCharacterInfo] = useState(false);

    useEffect(() => {
        if (creationContext.currentCharacterId === null)
            setShowCharacterInfo(false);
    }, [creationContext.currentCharacterId]);

    function toggleCharacterPanel() {
        setShowingCharacterPanel(!showingCharacterPanel);
        setCharacterButtonClicked(true);
        setShowCharacterInfo(false);
        setTimeout(() => {
            setCharacterButtonClicked(false);
        }, 100);
    }

    function SetCurrentCharacter(id) {
        const characterData = GetCharacterData(id);
        setCreationContext({
            ...creationContext,
            currentCharacterId: id,
            characterData
        });
        setShowCharacterInfo(true);
        setShowingCharacterPanel(false);
    }

    const characters = GetCharacterIds().map(id => (
        <>
            <motion.input type="button"
                style={{...button.enabled, width: "200px", height: "30px", fontSize: "18px", margin: "5px"}}
                onClick={() => SetCurrentCharacter(id)} 
                value={GetCharacterData(id).name}
                whileHover={button.whileHover}
            />
        </>
    ));

    const selectionPanel = (
        <motion.div style={shop.characterPanel}
            transition={{type:"spring"}}
            initial={{x:510}}
            animate={{x: showingCharacterPanel ? 0 : 510}}>
            <div style={rulebook.tableOfContentsTitle}>Your Characters</div>
            <div>{characters}</div>
        </motion.div>
    );

    const infoPanelStyle = {
        position: "fixed",
        backgroundColor: "white",
        borderStyle: "solid",
        borderColor: ovoPallete.borderBrown,
        width: "auto",
        minWidth: "100px",
        left: "25px",
        bottom: "25px",
        padding: "10px"
    };

    const infoTextStyle ={
        fontSize: "18px",
        fontFamily: "Arvo",
        marginTop: "10px",
        marginBottom: "15px"
    };

    const infoPanel = (
        <motion.div style={infoPanelStyle}
            transition={{type: "spring"}}
            initial={{y: 500}}
            animate={{y: showCharacterInfo ? 0 : 500}}
        >
            <h2 style={{fontSize:"24px", textAlign: "left", marginTop: "-10px", marginBottom: "0px"}}>{creationContext.characterData.name}</h2>
            <h4 style={infoTextStyle}>XP : {creationContext.characterData.xp}</h4>
            <h4 style={infoTextStyle}><strong style={{fontSize:"22px"}}>ⓜ</strong> : {creationContext.characterData.moolah}</h4>
        </motion.div>
    );

    return (
        <>
            {selectionPanel}
            {infoPanel}
            <motion.img style={that.characterButton}
                src="Beak Button (Beak Green).png" 
                draggable={false}
                initial={{y: 18}}
                transition={{type: "tween"}}
                whileHover={{x: -23, borderWidth: 3}}
                animate={{
                    x: 0,
                    scale: characterButtonClicked ? 0.75 : 1
                }}
                onClick={() => toggleCharacterPanel()}
            />
        </>
    );
}

export default CharacterPanel;
