import React, { useContext, useEffect, useState } from "react";
import { CreationContext } from "./creationContext";
import { button, rulebook, ovoPallete } from "../styling";
import { textAreaStyle } from "./nameAndDescription";
import ShopItems, { ShopType } from "../Shops/shopItems";
import { SaveCharacter } from "../localContext";

function DisplayCharacter() {
    const {creationContext, setCreationContext} = useContext(CreationContext);
    const [hp, setHp] = useState(creationContext.characterData.currentHp);
    const [hpToggle, setHpToggle] = useState(false);
    const [hpModifier, setHpModifier] = useState(0);
    const [maxHp, setMaxHp] = useState(creationContext.characterData.maxHp);
    const [maxHpToggle, setMaxHpToggle] = useState(false);
    const [maxHpModifier, setMaxHpModifier] = useState(0);
    const [xp, setXp] = useState(creationContext.characterData.xp);
    const [xpToggle, setXpToggle] = useState(false);
    const [xpModifier, setXpModifier] = useState(0);
    const [spentXp, setSpentXp] = useState(creationContext.characterData.spentXp);
    const [inventory] = useState(creationContext.characterData.inventory);
    const [motivation, setMotivation] = useState(creationContext.characterData.motivation);
    const [description, setDescription] = useState(creationContext.characterData.description);
    const [notes, setNotes] = useState(creationContext.characterData.notes);
    const [moolah, setMoolah] = useState(creationContext.characterData.moolah);
    const [moolahToggle, setMoolahToggle] = useState(false);
    const [moolahModifier, setMoolahModifier] = useState(0);
    const [cleverSkillUp, setCleverSkillUp] = useState(creationContext.characterData.clever.skillUps);
    const [cleverSkillUpToggle, setCleverSkillUpToggle] = useState(false);
    const [dextrousSkillUp, setDextrousSkillUp] = useState(creationContext.characterData.dextrous.skillUps);
    const [dextrousSkillUpToggle, setDextrousSkillUpToggle] = useState(false);
    const [gracefulSkillUp, setGracefulSkillUp] = useState(creationContext.characterData.graceful.skillUps);
    const [gracefulSkillUpToggle, setGracefulSkillUpToggle] = useState(false);
    const [irreverentSkillUp, setIrreverentSkillUp] = useState(creationContext.characterData.irreverent.skillUps);
    const [irreverentSkillUpToggle, setIrreverentSkillUpToggle] = useState(false);
    const [magicalSkillUp, setMagicalSkillUp] = useState(creationContext.characterData.magical.skillUps);
    const [magicalSkillUpToggle, setMagicalSkillUpToggle] = useState(false);
    const [martialSkillUp, setMartialSkillUp] = useState(creationContext.characterData.martial.skillUps);
    const [martialSkillUpToggle, setMartialSkillUpToggle] = useState(false);
    const [naturalSkillUp, setNaturalSkillUp] = useState(creationContext.characterData.natural.skillUps);
    const [naturalSkillUpToggle, setNaturalSkillUpToggle] = useState(false);
    const [strongSkillUp, setStrongSkillUp] = useState(creationContext.characterData.strong.skillUps);
    const [strongSkillUpToggle, setStrongSkillUpToggle] = useState(false);
    const [confirmReset, setConfirmReset] = useState("XP Reset");

    useEffect(() => {
        setCreationContext({
            ...creationContext,
            characterData: {
                ...creationContext.characterData,
                currentHp: hp,
                maxHp,
                xp,
                spentXp,
                inventory,
                description,
                motivation,
                moolah,
                notes,
                clever: {
                    ...creationContext.characterData.clever,
                    skillUps: cleverSkillUp
                },
                dextrous: {
                    ...creationContext.characterData.dextrous,
                    skillUps: dextrousSkillUp
                },
                graceful: {
                    ...creationContext.characterData.graceful,
                    skillUps: gracefulSkillUp
                },
                irreverent: {
                    ...creationContext.characterData.irreverent,
                    skillUps: irreverentSkillUp
                },
                natural: {
                    ...creationContext.characterData.natural,
                    skillUps: naturalSkillUp
                },
                magical: {
                    ...creationContext.characterData.magical,
                    skillUps: magicalSkillUp
                },
                martial: {
                    ...creationContext.characterData.martial,
                    skillUps: martialSkillUp
                },
                strong: {
                    ...creationContext.characterData.strong,
                    skillUps: strongSkillUp
                }
            }
        });
    }, [hp, maxHp, xp, spentXp, inventory, cleverSkillUp, dextrousSkillUp, gracefulSkillUp, irreverentSkillUp, magicalSkillUp, martialSkillUp, strongSkillUp, description, moolah, notes, motivation, naturalSkillUp]); // eslint-disable-line react-hooks/exhaustive-deps

    const statStyle = {
        fontFamily: "Artifika",
        fontSize: "24px",
        fontWeight: "bold",
        margin: "5px"
    }
    
    const sectionTitleStyle = {
        fontFamily: "Arvo",
        fontSize: "14px",
        fontWeight: "bold",
        margin: "5px auto 5px auto"
    }
    
    const attributeBoxStyle = {
        backgroundColor: "white",
        border: "solid",
        borderColor: "black",
        borderWidth: "2px",
        justifyContent: "center",
        padding: "5px",
        margin: "5px auto 5px auto",
        minWidth: "60px",
        maxWidth: "100px"
    }
    
    const statBoxStyle = {
        border: "solid",
        borderColor: "black",
        borderWidth: "2px",
        backgroundColor: "white",
        margin: "auto",
        justifyContent: "center",
        alignItems: "center"
    }
    
    const sectionTitle = (title) => {
        return <h4 style={{...sectionTitleStyle, textAlign: "center"}}>{title}</h4>
    }
    
    const attributeControl = (attribute, setAttribute, modifier, setModifier, limit = null) => {
        return (
            <div style={{display: "float", margin: "0px 0px -5px 0px"}}>
                <input
                    type="button"
                    style={{
                        ...button.enabled,
                        fontSize: "20px",
                        color: ovoPallete.heartRed,
                        borderColor: ovoPallete.heartRed,
                        margin: "0px 5px"
                    }} 
                    value="-"
                    onClick={() => {
                        var newValue = attribute-Number(modifier);
                        setAttribute(newValue)
                        setModifier(0);
                    }}
                    />
                <input type="number" style={{
                    width: "100px",
                    height: "40px",
                    textAlign: "center",
                    margin: "auto",
                    border: "none",
                    background: ovoPallete.backgroundBeige,
                    fontSize: "24px",
                    fontFamily: "Artifika"
                }}
                    value={modifier}
                    onChange={(e) => {setModifier(e.target.value)}}
                />
                <input
                    type="button"
                    style={{
                        ...button.enabled,
                        fontSize: "20px"
                    }}
                    value="+"
                    onClick={() => {
                        const newValue = attribute+Number(modifier);
                        if (limit !== null && newValue > limit) return;
                        setAttribute(newValue);
                        setModifier(0);
                    }}
                />
            </div>
        );
    }
    
    const attributeBox = (attributeName, attribute, setAttribute = null, modifier = null, setModifier = null, toggle = false, setToggle = () => {}, limit = null) => {
        return (
            <div style={{margin: "auto"}}>
                {setAttribute !== null && toggle === true && attributeControl(
                    attribute,
                    setAttribute,
                    modifier,
                    setModifier,
                    limit
                )}
                {!toggle && <div style={{height:"32px"}}/>}
                <div
                    style={{
                        ...attributeBoxStyle,
                        overflow:"visible"
                    }}
                    onClick={() => setToggle(!toggle)}>
                    <div style={sectionTitleStyle}>{attributeName}</div>
                    <div style={statStyle}>{attribute}</div>
                </div>
            </div>
        );
    }
    
    const skillUpButton = (skillUp, setSkillUp) => {
        const costOfNextSkillUp = (skillUp * 5) + 5;
        const canBuySkillUp = xp >= costOfNextSkillUp && skillUp < 5;
        const buySkillUp = () => {
            if (canBuySkillUp) {
                setSkillUp(skillUp+1);
                setSpentXp(spentXp+costOfNextSkillUp);
                setXp(xp-costOfNextSkillUp);
            }
        };
    
        return (
            <input
                type="button"
                style={canBuySkillUp ? 
                    {...button.enabled, backgroundColor: "white"} :
                    {...button.disabled}
                }
                value="SkillUp!?"
                onClick={() => buySkillUp()}
            />
        );
    }
    
    const statBox = (name, stat, skillUp, setSkillUp, toggle, setToggle) => {
        const skillUpDots = (numberOfSkillUps) => {
            let string = "";
            for (let i = 0; i < numberOfSkillUps; i++)
                string += "•";
    
            return string;
        }
    
        return (
            <div style={{margin: "auto"}}>
                {toggle && skillUpButton(skillUp, setSkillUp)}
                {!toggle && <div style={{height: "40px"}}/>}
                <div style={{...statBoxStyle, width: "80px", height: "80px"}} onClick={() => setToggle(!toggle)}>
                    <div style={sectionTitleStyle}>{name}</div>
                    <div style={statStyle}>{stat}</div>
                    <div style={{marginTop: "-10px", fontSize: "24px"}}>
                        {skillUpDots(skillUp)}
                    </div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        SaveCharacter(creationContext.currentCharacterId, creationContext.characterData);
    }, [creationContext.characterData, creationContext.currentCharacterId]);

    return (
        <div style={rulebook.content}>
            <h2 style={{marginBottom: "10px"}}>{creationContext.characterData.name}</h2>
            <input type="button"
                style={{...button.enabled, display: "flex", marginLeft: "auto", marginRight: "auto", marginBottom: "35px"}}
                value="Edit Character"
                onClick={() => setCreationContext({
                    ...creationContext,
                    creationStep: 1
                })}
            />
            <input type="button"
                style={{...button.enabled, display: "flex", marginLeft: "auto", marginRight: "auto", marginBottom: "35px"}}
                value={confirmReset}
                onClick={() => {
                    if (confirmReset === "XP Reset") {
                        setConfirmReset("Are you sure?");
                        setTimeout(() => {
                            setConfirmReset("XP Reset");
                        }, 2000);
                    } else {
                        setConfirmReset("Resetting...");
                        const newXp = spentXp+xp;
                        setCreationContext({
                            ...creationContext,
                            characterData: {
                                ...creationContext.characterData,
                                xp: newXp,
                                spentXp: 0,
                                skills: [],
                                talents: [],
                                clever: {
                                    ...creationContext.characterData.clever,
                                    skillUps: 0
                                },
                                dextrous: {
                                    ...creationContext.characterData.dextrous,
                                    skillUps: 0
                                },
                                graceful: {
                                    ...creationContext.characterData.graceful,
                                    skillUps: 0
                                },
                                irreverent: {
                                    ...creationContext.characterData.irreverent,
                                    skillUps: 0
                                },
                                natural: {
                                    ...creationContext.characterData.natural,
                                    skillUps: 0
                                },
                                magical: {
                                    ...creationContext.characterData.magical,
                                    skillUps: 0
                                },
                                martial: {
                                    ...creationContext.characterData.martial,
                                    skillUps: 0
                                },
                                strong: {
                                    ...creationContext.characterData.strong,
                                    skillUps: strongSkillUp
                                }
                            }
                        });
                        
                        setXp(newXp);
                        setSpentXp(0);
                        setCleverSkillUp(0);
                        setDextrousSkillUp(0);
                        setGracefulSkillUp(0);
                        setIrreverentSkillUp(0);
                        setMagicalSkillUp(0);
                        setMartialSkillUp(0);
                        setStrongSkillUp(0);

                        setTimeout(() => {
                            setConfirmReset("Reset XP");
                        }, 1000);
                    }
                }}
            />
            <h3 style={{fontFamily: "Arvo", color: "black", margin: "10px auto"}}>Description</h3>
            <textarea style={{...textAreaStyle, margin: "32px auto"}}
                defaultValue={description}
                onChange={(e) => setDescription(e.target.value)}
            />
            <h3 style={{fontFamily: "Arvo", color: "black", margin: "10px auto"}}>Motivation</h3>
            <textarea style={{...textAreaStyle, margin: "32px auto"}}
                defaultValue={motivation}
                onChange={(e) => setMotivation(e.target.value)}
            />
            <div style={{display: "flex", marginTop: "-25px", marginBottom: "-15px",textAlign: "center"}}>
                {attributeBox("HP",     hp,    setHp,    hpToggle,    setHpToggle,    hpModifier,    setHpModifier, maxHp)}
                {attributeBox("Max HP", maxHp, setMaxHp, maxHpToggle, setMaxHpToggle, maxHpModifier, setMaxHpModifier)}
                {attributeBox("XP", xp, setXp, xpToggle, setXpToggle, xpModifier, setXpModifier, 200-spentXp)}
                {attributeBox("Spent XP", spentXp)}
            </div>
            <div style={{display: "flex", margin: "10px auto", textAlign: "center", width: "100%"}}>
                {statBox("Clever",
                    creationContext.characterData.clever.value + (cleverSkillUp * 2),
                    cleverSkillUp,
                    setCleverSkillUp,
                    cleverSkillUpToggle,
                    setCleverSkillUpToggle
                )}
                {statBox("Dextrous",
                    creationContext.characterData.dextrous.value + (dextrousSkillUp * 2),
                    dextrousSkillUp,
                    setDextrousSkillUp,
                    dextrousSkillUpToggle,
                    setDextrousSkillUpToggle
                )}
                {statBox("Graceful",
                    creationContext.characterData.graceful.value + (gracefulSkillUp * 2),
                    gracefulSkillUp,
                    setGracefulSkillUp,
                    gracefulSkillUpToggle,
                    setGracefulSkillUpToggle
                )}
                {statBox("Irreverent",
                    creationContext.characterData.irreverent.value + (irreverentSkillUp * 2),
                    irreverentSkillUp,
                    setIrreverentSkillUp,
                    irreverentSkillUpToggle,
                    setIrreverentSkillUpToggle
                )}
            </div>
            <div style={{display: "flex", margin: "0 auto", textAlign: "center", width: "100%"}}>
                {statBox("Magical",
                    creationContext.characterData.magical.value + (magicalSkillUp * 2),
                    magicalSkillUp,
                    setMagicalSkillUp,
                    magicalSkillUpToggle,
                    setMagicalSkillUpToggle
                )}
                {statBox("Martial",
                    creationContext.characterData.martial.value + (martialSkillUp * 2),
                    martialSkillUp,
                    setMartialSkillUp,
                    martialSkillUpToggle,
                    setMartialSkillUpToggle
                )}
                {statBox("Natural",
                    creationContext.characterData.natural.value + (naturalSkillUp * 2),
                    naturalSkillUp,
                    setNaturalSkillUp,
                    naturalSkillUpToggle,
                    setNaturalSkillUpToggle
                )}
                {statBox("Strong",
                    creationContext.characterData.strong.value + (strongSkillUp * 2),
                    strongSkillUp,
                    setStrongSkillUp,
                    strongSkillUpToggle,
                    setStrongSkillUpToggle
                )}
            </div>
            <h3 style={{fontFamily: "Arvo", color: "black", margin: "20px auto 10px auto"}}>Starting Abilities</h3>
            {sectionTitle("Starting Title")}
            {(creationContext.characterData.startingTalent !== null) && <ShopItems
                shopType={ShopType.DISPLAY_LIST}
                items={[creationContext.characterData.startingTalent]}
            />}
            {sectionTitle("Starting Skill")}
            {(creationContext.characterData.startingSkill !== null) && <ShopItems
                shopType={ShopType.DISPLAY_LIST}
                items={[creationContext.characterData.startingSkill]}
            />}
            <h3 style={{fontFamily: "Arvo", color: "black", margin: "10px auto"}}>Learned Abilities</h3>
            <ShopItems shopType={ShopType.DISPLAY_LIST} 
                items={[
                    ...creationContext.characterData.talents,
                    ...creationContext.characterData.skills
                ]}
            />
            <h3 style={{fontFamily: "Arvo", color: "black", margin: "20px auto 10px auto"}}>Inventory</h3>
            <div style={{display: "flex", margin: "auto", textAlign: "center", width: "90%"}}>
                {attributeBox("ⓜ", moolah, setMoolah, moolahToggle, setMoolahToggle, moolahModifier, setMoolahModifier)}
            </div>

            <h3 style={{fontFamily: "Arvo", color: "black", margin: "20px auto 10px auto"}}>Notes</h3>
            <textarea style={{...textAreaStyle, fontSize: "12px", height: "400px", margin: "32px auto"}}
                defaultValue={notes}
                onChange={(e) => setNotes(e.target.value)}
            />
            <ShopItems shopType={ShopType.DISPLAY_LIST} items={creationContext.characterData.inventory}/>
        </div>
    );
}

export default DisplayCharacter;