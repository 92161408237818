import { BrowserRouter, Routes, Route } from "react-router-dom";
import SourceBook from "../Source Book/sourceBook";
import React, { useState } from "react";
import { detectEnvironment, ENVIRONMENT } from "../environment";
import { adventum, that } from "../styling";
import { CreationContext, defaultContext } from "../Character Creation/creationContext";

function Home() {
    const env = detectEnvironment();
    const [creationContext, setCreationContext] = useState(defaultContext);

    return (
        <div>
            <img style={that.logo} src="That Owl Logo.png" alt="OvO)"/>
            <div style={adventum}>Adventum</div>
            <CreationContext.Provider value={{creationContext, setCreationContext}}>
                <BrowserRouter>
                    <Routes>
                        <Route path="*" element={<SourceBook />}/>
                    </Routes>
                </BrowserRouter>
            </CreationContext.Provider>
            <p>
                This build is in the {env === ENVIRONMENT.DEV ? "development" : 
                env === ENVIRONMENT.SANDBOX ? "sandbox" : "production"} environment.
            </p>
        </div>
    );
}

export default Home;
