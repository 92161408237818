export const detectEnvironment = () => {
    const url = window.location.href;
    return url.includes("localhost") ? ENVIRONMENT.DEV
        : url.includes("sandbox") ? ENVIRONMENT.SANDBOX : ENVIRONMENT.PROD;
}

export const notInProduction = () => detectEnvironment() !== ENVIRONMENT.PROD;

export const ENVIRONMENT = Object.freeze({
    DEV: 0,
    SANDBOX: 1,
    PROD: 2
});