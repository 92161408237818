const AdventumPropKey = Object.freeze({
    ID_LIST: "characterList"
});

export const DefaultCharacter = {
    name: "(OvO)",
    currentHp: 10,
    maxHp: 10,
    armor: 0,
    tempHp: 0,
    xp: 10,
    moolah: 0,
    spentXp: 0,
    description: "",
    startingTalent: null,
    startingSkill: null,
    startingItems: [],
    clever: {
        value: 0,
        skillUps: 0
    },
    dextrous: {
        value: 0,
        skillUps: 0
    },
    graceful: {
        value: 0,
        skillUps: 0
    },
    irreverent: {
        value: 0,
        skillUps: 0
    },
    magical: {
        value: 0,
        skillUps: 0
    },
    martial: {
        value: 0,
        skillUps: 0
    },
    natural: {
        value: 0,
        skillUps: 0
    },
    strong: {
        value: 0,
        skillUps: 0
    },
    motivation: "",
    skills:[],
    talents: [],
    inventory:[],
    relationships: [],
    notes: ""
};

export function GetCharacterIds() {
    const localData = localStorage.getItem(AdventumPropKey.ID_LIST);
    if (localData === null)
        return [];

    const array = JSON.parse(localData);

    return array;
}

export function GetCharacterData(id) {
    const characterData = localStorage.getItem(id);
    if (characterData === null)
        return null;

    return JSON.parse(characterData);
}

export function SaveCharacter(id, data) {
    if (id === null || id === undefined)
        throw new Error(`SaveCharacter() received ${id === null ? 'null' : 'undefined'} characterId!`);

    if (data === null || data === undefined)
        throw new Error(`SaveCharacter() received ${data === null ? 'null' : 'undefined'} characterData!`);

    var characterIdList = GetCharacterIds();
    if (!characterIdList.includes(id))
        characterIdList.push(id);

    localStorage.setItem(AdventumPropKey.ID_LIST, JSON.stringify(characterIdList));
    localStorage.setItem(id, JSON.stringify(data));
}

export function DeleteCharacter(id) {
    if (GetCharacterData(id) === null)
        return;

    var idList = GetCharacterIds();
    for(let i = 0; i < idList.length; i++) {
        if (idList[i] === id) {
            idList.splice(i, 1);
            break;
        }
    }
    
    localStorage.setItem(AdventumPropKey.ID_LIST, JSON.stringify(idList));
    localStorage.removeItem(id);
}
